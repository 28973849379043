/* Success Toast Style */
.custom-success-toast {
  display: flex;
  align-items: center; /* Vertically align items */
  width: 500px;
  max-width: 600px;
  max-height: 56px;
  padding: 8px 16px;
  border-radius: 4px;
  background: #008553; /* Success color */
  box-shadow: 0px 0px 24px 0px rgba(0, 92, 188, 0.08); /* Elevation effect */
  color: #fff; /* White text */

  /* Font style */
}

@media (max-width: 600px) {
  .custom-success-toast {
    width: 80%; /* Set to 30% of the screen width */
    min-width: 120px; /* Ensure it doesn't become too small */
  }
}

/* Custom Icon Styling */
.custom-success-toast .Toastify__icon--success {
  border-radius: 50%;
  padding: 5px;
  font-size: 20px;
  color: white; /* White icon color */
}

/* Close Button Styling */
.custom-success-toast .Toastify__close-button {
  color: white; /* White close button */
  font-size: 24px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  align-self: center; /* Vertically center the close button */
}

/* Error Toast Style */
.custom-error-toast {
  display: flex;
  align-items: center; /* Vertically align items */
  width: 500px;
  max-width: 600px;
  max-height: 56px;
  padding: 8px 16px;
  border-radius: 4px;
  background: #d40020; /* Error color (red) */
  box-shadow: 0px 0px 24px 0px rgba(211, 47, 47, 0.08); /* Elevation effect */
  color: #fff; /* White text */

  /* Font style */
}
@media (max-width: 600px) {
  .custom-error-toast {
    width: 80%; /* Set to 30% of the screen width */
    min-width: "120px";
  }
}
/* Custom Icon Styling for Error */
.custom-error-toast .Toastify__icon--error {
  border-radius: 50%;
  padding: 5px;
  font-size: 20px;
  color: white; /* White icon color */
}

/* Close Button Styling for Error */
.custom-error-toast .Toastify__close-button {
  color: white; /* White close button */
  font-size: 24px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  align-self: center; /* Vertically center the close button */
}
