.external-step-two-form .dropdown-input-row {
    width: 100%
}

.external-step-two-form .text-area {
    width: 100%;
    margin-bottom: 19px;
}

.external-step-two-form .text-area .text-area-editor-wrapper {
    height: 122px
}