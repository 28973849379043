.SummaryCard {
    display: flex;
    padding: var(--Spacing-spacing-24, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-spacing-16, 16px);
    border-radius: var(--Corner-radius-effect-corner-2, 8px) !important;
    box-shadow: var(--Elevation-X-Elevation-01, 0px) var(--Elevation-Y-Elevation-01, 0px) var(--Elevation-Blur-Elevation-01, 24px) var(--Elevation-Spread-Elevation-01, 0px) var(--Elevation-Colors-Elevation-01, rgba(0, 92, 188, 0.08));
}
.SummaryTitle {
    font-feature-settings: 'liga' off, 'clig' off  !important;
    font-family: var(--Typography-Font-Family-Heading, Inter)  !important;
    font-size: var(--Font-Size-H5, 24px)  !important;
    font-style: normal  !important;
    font-weight: 700  !important;
    line-height: var(--Line-Height-H5, 32px)  !important;
}

.SummaryContentBox {
    display: flex;
    align-items: flex-start   !important;
    gap: 8px  !important;
    flex: 1 0 0  !important;
    align-self: stretch  !important;
    /* background: var(--Tokens-Background-Default, #F3F7FD)  !important; */
}

.SummaryContents {
    font-family: var(--Typography-Font-Family-Body, Inter) !important;
    font-size: var(--Font-Size-Body, 16px) !important;
    font-style: normal;
    font-weight: 400;
    line-height: var(--Line-Height-Body, 22px) !important;
    display: flex;
    padding: var(--Spacing-spacing-16, 12px)  !important;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    width: 89%  !important;
}
.DetailCardBox {
    display: flex;
    padding: var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-16, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-spacing-8, 8px);
    flex: 1 0 0;
    border-radius: var(--Corner-radius-corner-radius-small, 8px);
    background: var(--Tokens-Background-Layer-02, #EFF5FC);
    min-height: 62px;
}

.DetailCardGridFirstContent {
    font-family: var(--Typography-Font-Family-Body, Inter) !important;
    font-size: var(--Font-Size-Small, 14px) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: var(--Line-Height-Small, 20px) !important;
}

.DetailCardGridSecondContent {
    font-feature-settings: 'liga' off, 'clig' off !important;
    font-family: var(--Typography-Font-Family-Body, Inter) !important;
    font-size: var(--Font-Size-XSmall, 12px) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: var(--Line-Height-XSmall, 18px) !important;
}
.TagsContent {
    display: flex;
    height: var(--Spacing-spacing-24, 24px);
    padding: var(--Spacing-spacing-8, 8px) var(--Spacing-spacing-02, 4px) !important;
    align-items: center;
    gap: var(--Spacing-spacing-2, 2px);
    border-radius: var(--Corner-radius-effect-corner-1, 4px);
    font-feature-settings: 'liga' off, 'clig' off !important;
    font-family: var(--Typography-Font-Family-Body, Inter) !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 133% !important;
}
.LogoButton {
    display: flex;
    /* padding: var(--Spacing-spacing-02, 4px) var(--Spacing-spacing-0, 0px); */
    justify-content: center;
    align-items: center;
    gap: var(--Spacing-spacing-02, 4px);
    flex: 1 0 0;
    padding: 0 !important;
}

.LogoContent {
    color: var(--Tokens-Button-Borderless, #005CBC);
    font-family: var(--Typography-Font-Family-Body, Inter) !important;
    font-size: var(--Font-Size-Small, 14px) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: var(--Line-Height-Small, 20px) !important;
    text-transform: capitalize;
}

.DetailCardGridContainer {
  
   margin-top: 12px !important;
}