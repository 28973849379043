.responsive-main {
  flex: 1;
  margin-top: 6rem;
}

/* For screens above 1200px */
@media (min-width: 1493px) {
  .responsive-main {
    margin-top: 8.6rem; /* 158px */
  }
}

/* For screens between 600px and 937px (Tablets & Small Screens) */
@media (max-width: 937px) {
  .responsive-main {
    margin-top: 6rem;
  }
}

/* 📱 For Mobile Screens (Below 600px) */
@media (max-width: 600px) {
  .responsive-main {
    margin-top: 5.2rem; /* Adjust as needed */
  }
}

/* 📱 For Extra Small Mobile Screens (Below 375px) */
@media (max-width: 390px) {
  .responsive-main {
    margin-top: 6.9rem; /* Adjust as needed */
  }
}
