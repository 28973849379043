.sign-in-access-request-modal .model-body .title {
  position: relative !important;
  margin-bottom: 12px;
  overflow: visible !important;
}

.sign-in-access-request-modal .model-body .model-body-slot-view {
  overflow: visible !important;
}
.sign-in-access-request-modal
  .model-body
  .modal-footer
  .button-hierarchy
  .button-hierarchy-left {
  display: none;
}

.sign-in-access-request-modal .model-body input {
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-text-primary);
}
.sign-in-access-request-modal .model-body .text-input {
  width: 400px;
}

.sign-in-access-request-modal .model-body .text-input .input-wrapper input {
  margin-right: 0;
}

.sign-in-access-request-modal .modal-form-country-dropdown {
  width: 400px !important;
  margin-bottom: 7px;
}

.sign-in-access-request-modal
  .modal-form-country-dropdown
  .label-element
  .icon {
  display: none;
}

.sign-in-access-request-modal .modal-form-country-dropdown .dropdown-input-row {
  width: 100% !important;
}

.sign-in-access-request-modal .modal-form-country-dropdown .dropdown-parent {
  width: 100% !important;
}

.sign-in-access-request-modal .text-input .feedback .icon {
  display: none;
}
.sign-in-access-request-modal
  .modal-form-country-dropdown
  .dropdown-input-row
  .icon-container {
  position: relative;
  display: inline-flex;
  align-self: center;
}

.sign-in-access-request-modal .modal-form-country-dropdown .menu-list {
  max-height: 90px !important;
}

.sign-in-access-request-modal
  .access-request-terms-checkbox
  input:checked::before {
  height: 35%;
}

@media screen and (max-width: 1023.99px) {
  .sign-in-access-request-modal .model-body .model-body-slot-view {
    overflow: auto !important;
    margin-top: 0.5rem;
  }
  .sign-in-access-request-modal .model-body .text-input {
    width: 100%;
  }
  .sign-in-access-request-modal .modal-form-country-dropdown {
    width: 100% !important;
  }

  .sign-in-access-request-modal .modal-form-country-dropdown .menu-list {
    max-height: 240px !important;
  }
}
