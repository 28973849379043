.search-box .search-input {
    width: 100%;
    
}

.search-box .search-input .input-wrapper input {
    background-color: #EFF5FC;
    border-bottom: 1px #C0C0C0 solid;
}
.search-box .search-input .input-wrapper input:valid, .search-box .search-input .input-wrapper input:focus, .search-box .search-input .input-wrapper input:hover {
    border-bottom: 1px #C0C0C0 solid !important;
}

