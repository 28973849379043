@tailwind base;
@tailwind components;
@tailwind utilities;

/* ---------------------- CSS to apply Peacock theme ---------------- */
.radio .radio-item-container span[aria-checked="true"] {
    color: var(--color-interaction-active);
}
.radio .radio-item-container span.dark[aria-checked="true"] {
    color: var(--primary-400Dark);
}

.dropdown-input-row .dropdown-parent {
    width: 100% !important;
}

.menu-list-item .checkbox input::before {
    box-sizing: content-box;
}

#root {
    font-family: Inter;
    color: var(--color-text-primary);
}