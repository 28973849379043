.library-accordion-container {
  padding: 16px 8px 16px 8px;
}

.library-accordion-container .accordion-item .summary .buttonBase {
  border-top-width: 0 !important;
  border-bottom-width: 2px !important;
  height: 54px !important;
  border-color: #D7E5F8;
}

.library-accordion-container .accordion-item .summary .buttonBase:hover {
  background: transparent !important ;
}

.library-accordion-container .accordion-item .summary .buttonBase > span {
  color: #005cbc;
}

.library-accordion-container .accordion-item .accordion-item-label-container {
  display: flex;
  flex-direction: unset !important;
  justify-content: space-between !important;
}

.library-accordion-container .accordion-item-label {
  font-weight: 700;
  text-align: left;
}
.library-accordion-container .accordion-item-label-container .xlarge {
  font-weight: 700;
  background: #616161;
}

.library-accordion-container .accordion-item-subtitle {
  height: 22px;
  gap: 4px;
  font-size: medium;
  font-weight: 400;
  color: #005cbc;
}

.dark-theme-text-color .accordion-item-label {
  color: #ededed !important;
}
.css-1u6lzl2-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #616161 !important;
}

/* Mobile-specific styles using a media query */
@media screen and (max-width: 768px) {
  .library-accordion-container {
   
    align-items: center;
    align-self: stretch;
    padding: 8px 4px; /* Further reduce padding for smaller devices */
  }
  
  .library-accordion-container .accordion-item .summary .buttonBase  {
    padding: 1rem !important;
    height: 4rem !important;
  }

  .library-accordion-container .accordion-item .summary .buttonBase > span {
    font-size: small; /* Reduce font size */
  }

  .library-accordion-container .accordion-item .accordion-item-label-container {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
    display: flex;
    justify-content: center;
  }

  .library-accordion-container .accordion-item-label {
    font-size: medium; /* Slightly smaller font for labels */
  }

  .library-accordion-container .accordion-item-subtitle {
    font-size: small; /* Smaller subtitles for mobile */
    color: #005cbc;
  }
}
