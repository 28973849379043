
.datatable-custom .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor{
    width: 190px !important;
}
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft{
    width: 190px !important;
}
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft{
    width: 190px !important;
}
.textOverflowWithEllipsis {
    text-overflow: ellipsis;
}